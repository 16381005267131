.block__menu--main {
  &.contextual-links-region {
    position: static;
  }

  ul {
    @extend %list-clean;
  }

  li {
    margin-top: 0;

    a {
      @extend %typ-no-underline;
      padding: 5px 12px;
      display: block;
      font-size: 17px;
      font-weight: bold;
      color: $white;

      &:hover,
      &:focus {
        background-color: darken($secondary-color, 5%);
        color: $white;
      }

      &.is-active,
      &.active-trail {
        background-color: $white;
        color: $secondary-color;
      }
    }
  }

  @media screen and (min-width: $tablet-portrait) {
    li {
      float: left;

      a {
        padding: 0 6px;
        font-size: .75rem;
        height: 85px;
        line-height: 85px;
      }

      &.last {
        padding-top: 23.5px;
        margin: 0 5px;
        height: 85px;
        line-height: 85px;

        a {
          height: auto;
          padding: 6px;
          line-height: 1.5rem;
          border: 1px solid $white;
          border-radius: 4px;
        }
      }
    }
  }

  @media screen and (min-width: $desktop-narrow) {
    li {
      a {
        font-size: 15px;
        padding: 0 12px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    li {
      &.last {
        margin: 0 15px;

        a {
          padding: 6px 12px;
        }
      }
    }
  }
}
