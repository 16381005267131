.table-wrapper {
  @extend %responsive-horizontal-parent;
}

table {
  @extend %margin-bottom-30;
  display: block;
  overflow-x: auto;
  border: 0;
  height: auto !important;

  th,
  td {
    border: 1px solid $grey;
    padding: 5px;
    text-align: left;
  }
}

@media screen and (min-width: $tablet-portrait) {
  .table-wrapper {
    white-space: normal;
  }
}
