.block__domains {
  .view-content {
    @extend %flex-stretch;
  }

  .group__wrapper {
    @include span(12 of 12);
    margin-bottom: 40px;

    @media screen and (min-width: $mobile-landscape-wide) {
      @include span(6 of 12);

      &:nth-child(2n) {
        @include last;
      }
    }

    @media screen and (min-width: $desktop-narrow) {
      @include span(4 of 12);

      &:nth-child(2n) {
        @include span(4 of 12);
      }

      &:nth-child(3n) {
        @include last;
      }
    }

    h3 {
      @extend %flex;
      -ms-flex-align: center;
      align-items: center;

      img {
        width: 36px;
        margin-right: 10px;
      }
    }

    .views-row {
      margin-left: 50px;
      border-top: 1px solid $grey-dark;

      &.views-row-last {
        border-bottom: 1px solid $grey-dark;
      }
      
      a {
        @extend %typ-no-underline;
        color: $primary-color;
        
        &:hover,
        &:focus {
          color: lighten($primary-color, 20);
        }
      }
    }
  }
}