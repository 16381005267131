.slicknav_menu {
  display: none;
}

@media screen and (max-width: $tablet-portrait - 1) {
  .js {
    .block__menu--main .menu {
      display: none;
    }

    .slicknav_menu {
      display: block;
      text-align: left;

      .slicknav_btn {
        position: absolute;
        right: 55px;
        top: 26px;
        text-decoration: none;
      }

      .slicknav_menutxt {
        display: none;
      }

      .slicknav_icon {
        display: block;
        position: relative;
        width: 100%;
        height: 30px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        .slicknav__active-item {
          margin-left: 55px;
          height: 30px;
          line-height: 30px;
          font-family: "MeltowSan", Helvetica, Arial, sans-serif;
          font-size: 1.25rem;
          padding-top: 3px;
          color: $white;
        }

        span {
          display: block;
          position: absolute;
          height: 6px;
          width: 40px;
          background: $white;
          border-radius: 6px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;

          &:nth-child(1) {
            top: 0px;
          }

          &:nth-child(2) {
            top: 12px;
          }

          &:nth-child(3) {
            top: 24px;
          }
        }

        &.open span:nth-child(1) {
          top: 12px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }

        &.open span:nth-child(2) {
          opacity: 0;
        }

        &.open span:nth-child(3) {
          top: 12px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }

      .slicknav_nav {
        padding-bottom: 15px;
        text-align: center;

        a {
          font-size: 1.25rem;
          line-height: 2rem;
        }
      }
    }
  }
}
