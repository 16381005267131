.block__menu--footer {
  ul {
    @extend %list-clean;
  }
  
  li {
    @extend %inline-block;

    a {
      color: $primary-color;

      &:hover,
      &:focus {
        @extend %typ-no-underline;
      }
    }

    &:before {
      content: ' | ';
      margin: 0 10px;
    }

    &.first:before {
      content: '';
    }

    @media screen and (min-width: $tablet-portrait) {
      &:before {
        margin: 0;
      }

      &.first:before {
        content: ' | ';
      }
    }

    @media screen and (min-width: $desktop-narrow) {
      &:before {
        margin: 0 10px;
      }

      &.first:before {
        content: ' | ';
      }
    }
  }
}
