.logo {
  @extend %inline-block;
  padding-top: 15px;
  height: 85px;
  line-height: 85px;

  &:hover,
  &:focus {
    opacity: .8;
  }

  img {
    width: 125px;
  }
}
