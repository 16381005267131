.view-business-cases,
.view-nieuwsberichten {
  .views-exposed-widgets {
    margin-bottom: 20px;

    select {
      height: 40px;
    }
  }
}
