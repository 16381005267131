.block__logout {
  @extend %clearfix;
  margin-bottom: 20px;
  text-align: right;

  .content {
    a {
      @extend %typ-no-underline;
      padding: 8px 6px;
      background: $secondary-color;
      color: $white;
      font-weight: bold;
      border: 1px solid $secondary-color;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: darken($secondary-color, 5);
        border-color: darken($secondary-color, 5);
      }
    }
  }
}
