.messages {
  border: 3px solid;
  padding: 15px;
  margin-bottom: 20px;
  color: $white;
  font-weight: bold;

  &.status {
    background-color: $status-light;
    border-color: $status-dark;
  }

  &.warning {
    background-color: $warning-light;
    border-color: $warning-dark;
  }

  &.error {
    background-color: $error-light;
    border-color: $error-dark;
  }
}
