.node-type-business-case {
  .field-name-field-domain,
  .field-name-contenttype {
    @extend %inline-block;
    vertical-align: middle;

    > .field-items > .field-item {
      @extend %inline-block;
      margin-right: 2px;
    }
  }

  .field-name-field-domain-category,
  .field-name-contenttype .field-item {
    margin-bottom: 10px;
    padding: 0 4px;
    font-weight: bold;
    color: $white;
  }

  .field-name-field-domain-category {
    background-color: $secondary-color;
  }

  .field-name-contenttype .field-item {
    background-color: $tertiary-color;

    p {
      margin-bottom: 0;
    }
  }

  .field-name-field-afbeelding {
    margin-bottom: 20px;
  }

  .node-business-case.view-mode-full {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}
