.block__members-info {
  margin-bottom: 20px;
  border-bottom: 1px solid $white;

  h2:before {
    @extend %inline-block;
    background: url('../../dist/image/icon_members.png') no-repeat left center;
    background-size: contain;
    content: ' ';
    height: 24px;
    width: 24px;
    margin-right: 1rem;
  }
}

@media screen and (min-width: $mobile-landscape-wide) {
  .block__members-info {
    margin-bottom: 0;
    border-bottom: 0;
  }
}
