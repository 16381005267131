.form-submit {
  @extend %button-primary;
}

.form-required {
  color: $error-dark;
}

.form-item {
  margin-bottom: 1rem;
  
  label {
    display: block;
  }
}

.form-type-radio,
.form-type-checkbox {
  margin-bottom: 0;

  label {
    display: inline;
  }
}

textarea,
.form-text,
.form-select,
.form-date,
.form-number,
.form-email,
.form-tel,
.form-url {
  padding: 8px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &.error {
    border-color: $error-dark;
  }

  &:focus {
    outline: none;
    border-color: $grey-dark;
  }
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.description {
  font-size:.75rem;
  line-height: 1rem;
  margin-bottom: 20px;
}
