.breadcrumb {
  padding: 15px 0 5px;

  a,
  span {
    @extend %typ-no-underline;
    @extend %typ-lowercase;
    font-size: .875rem;
    color: $primary-color;
  }
}
