.block__intro, .paragraph__container .container,
.field-name-field-paragraph .container, .paragraph__container .paragraphs-item-statistics,
.paragraph__container .paragraphs-item-tekstblok-2col,
.paragraph__container .paragraphs-item-tekstblok-1col,
.paragraph__container .paragraphs-item-quote,
.paragraph__container .paragraphs-item-blok-2-1,
.paragraph__container .paragraphs-item-text-image,
.field-name-field-paragraph .paragraphs-item-statistics,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col,
.field-name-field-paragraph .paragraphs-item-quote,
.field-name-field-paragraph .paragraphs-item-blok-2-1,
.field-name-field-paragraph .paragraphs-item-text-image, .paragraph__container .paragraphs-item-banner-full-width,
.field-name-field-paragraph .paragraphs-item-banner-full-width, .page-header {
  position: relative;
}

.block__contactperson .label-inline, .block__doormat.last .twitter:before,
.block__doormat.last .linkedin:before,
.block__doormat.last .youtube:before,
.block__doormat.last .facebook:before, .block__members-info h2:before, .block__statistics h2:before, .block__menu--footer li, .block__menu--secondary li, .logo, .pager li, .paragraph__container .paragraphs-item-menu .label-inline,
.field-name-field-paragraph .paragraphs-item-menu .label-inline, .paragraph__container .paragraphs-item-menu .paragraph__menu ul,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__menu ul, .paragraph__container .paragraphs-item-menu .paragraph__menu li,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__menu li, .paragraph__container .paragraphs-item-statistics .paragraph__title--statistics:before,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__title--statistics:before, .tabs li, .node-type-business-case .field-name-field-domain,
.node-type-business-case .field-name-contenttype, .node-type-business-case .field-name-field-domain > .field-items > .field-item,
.node-type-business-case .field-name-contenttype > .field-items > .field-item, .node-type-domain .field__domain--documents .label-above:before, .node-type-domain .field__domain--news .label-above:before, .node-type-domain .field__domain--links .label-above:before, .node-type-ct-event .datum .label-inline,
.node-type-ct-event .locatie .label-inline, .node-type-ct-news .field-name-field-domain,
.node-type-ct-news .field-name-contenttype,
.node-type-ct-news .field-name-field-type, .node-type-ct-news .field-name-field-domain > .field-items > .field-item,
.node-type-ct-news .field-name-contenttype > .field-items > .field-item,
.node-type-ct-news .field-name-field-type > .field-items > .field-item, .view-business-cases .field-name-field-domain > .field-items > .field-item, .view-business-cases .field-name-field-domain-category, .block__members .label-inline,
.block__members .field-name-field-postcode,
.block__members .field-name-field-gemeente,
.block__members .field-name-field-gemeente-fr, .block__mailchimp .more-link,
.block__mailchimp .subscribe-link {
  display: inline-block;
}

.form-submit, .view-business-cases .more-link a, .view-documents--domains .more-link a,
.view-documents--news .more-link a,
.view-documents--page .more-link a, .view-news--domains .more-link a,
.view-news--news .more-link a,
.block__news--home .more-link a, .block__mailchimp .more-link a, .block__publications .more-link a, .tabs a, .node-type-domain .field__domains--link .field-name-field-link a, .node-type-ct-news .field-name-field-bijlagen .file a, .view-documents--domains .field-name-field-bijlagen .file a,
.view-documents--news .field-name-field-bijlagen .file a,
.view-documents--page .field-name-field-bijlagen .file a, .block__news--home .field-name-field-bijlagen .file a {
  border: 1px solid;
  border-radius: 5px;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
}

.form-submit, .view-business-cases .more-link a, .view-documents--domains .more-link a,
.view-documents--news .more-link a,
.view-documents--page .more-link a, .view-news--domains .more-link a,
.view-news--news .more-link a,
.block__news--home .more-link a, .block__mailchimp .more-link a, .block__publications .more-link a {
  border-color: #527B97;
  color: #527B97;
  background-color: #fff;
  font-weight: bold;
}

.form-submit:hover, .view-business-cases .more-link a:hover, .view-documents--domains .more-link a:hover,
.view-documents--news .more-link a:hover,
.view-documents--page .more-link a:hover, .view-news--domains .more-link a:hover,
.view-news--news .more-link a:hover,
.block__news--home .more-link a:hover, .block__mailchimp .more-link a:hover, .block__publications .more-link a:hover, .form-submit:focus, .view-business-cases .more-link a:focus, .view-documents--domains .more-link a:focus,
.view-documents--news .more-link a:focus,
.view-documents--page .more-link a:focus, .view-news--domains .more-link a:focus,
.view-news--news .more-link a:focus,
.block__news--home .more-link a:focus, .block__mailchimp .more-link a:focus, .block__publications .more-link a:focus {
  color: #fff;
  background-color: #527B97;
}

.tabs a {
  border-color: #444;
  color: #fff;
  background-color: #444;
  font-weight: bold;
}

.tabs a:hover, .tabs a:focus {
  color: #fff;
  background-color: #515151;
}

.node-type-domain .field__domains--link .field-name-field-link a, .node-type-ct-news .field-name-field-bijlagen .file a, .view-documents--domains .field-name-field-bijlagen .file a,
.view-documents--news .field-name-field-bijlagen .file a,
.view-documents--page .field-name-field-bijlagen .file a, .block__news--home .field-name-field-bijlagen .file a {
  border-color: #527B97;
  border-radius: 0;
  background-color: #527B97;
  color: #fff;
  padding: 0 4px;
  font-weight: bold;
  font-size: .875rem;
}

.node-type-domain .field__domains--link .field-name-field-link a:hover, .node-type-ct-news .field-name-field-bijlagen .file a:hover, .view-documents--domains .field-name-field-bijlagen .file a:hover,
.view-documents--news .field-name-field-bijlagen .file a:hover,
.view-documents--page .field-name-field-bijlagen .file a:hover, .block__news--home .field-name-field-bijlagen .file a:hover, .node-type-domain .field__domains--link .field-name-field-link a:focus, .node-type-ct-news .field-name-field-bijlagen .file a:focus, .view-documents--domains .field-name-field-bijlagen .file a:focus,
.view-documents--news .field-name-field-bijlagen .file a:focus,
.view-documents--page .field-name-field-bijlagen .file a:focus, .block__news--home .field-name-field-bijlagen .file a:focus {
  color: #527B97;
  background-color: #fff;
}

.block__logout:before, .page-filedepot #filedepotmodule:before, .paragraph__container .paragraphs-item-statistics .paragraph__wrapper:before,
.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__wrapper:before,
.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__wrapper:before,
.paragraph__container .paragraphs-item-quote .paragraph__wrapper:before,
.paragraph__container .paragraphs-item-blok-2-1 .paragraph__wrapper:before,
.paragraph__container .paragraphs-item-text-image .paragraph__wrapper:before,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__wrapper:before,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__wrapper:before,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__wrapper:before,
.field-name-field-paragraph .paragraphs-item-quote .paragraph__wrapper:before,
.field-name-field-paragraph .paragraphs-item-blok-2-1 .paragraph__wrapper:before,
.field-name-field-paragraph .paragraphs-item-text-image .paragraph__wrapper:before, .view-news--domains:before,
.view-news--news:before,
.block__news--spotlight:before,
.block__news--home:before, .page-below-content:before, .page-doormat:before, .block__logout:after, .page-filedepot #filedepotmodule:after, .paragraph__container .paragraphs-item-statistics .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-quote .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-blok-2-1 .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-text-image .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-quote .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-blok-2-1 .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-text-image .paragraph__wrapper:after, .view-news--domains:after,
.view-news--news:after,
.block__news--spotlight:after,
.block__news--home:after, .page-below-content:after, .page-doormat:after {
  content: '';
  display: table;
}

.block__logout:after, .page-filedepot #filedepotmodule:after, .paragraph__container .paragraphs-item-statistics .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-quote .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-blok-2-1 .paragraph__wrapper:after,
.paragraph__container .paragraphs-item-text-image .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-quote .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-blok-2-1 .paragraph__wrapper:after,
.field-name-field-paragraph .paragraphs-item-text-image .paragraph__wrapper:after, .view-news--domains:after,
.view-news--news:after,
.block__news--spotlight:after,
.block__news--home:after, .page-below-content:after, .page-doormat:after {
  clear: both;
}

.view-business-cases .view-content, .view-documents--domains .view-content,
.view-documents--news .view-content,
.view-documents--page .view-content, .block__domains .view-content, .view-news--domains .view-content,
.view-news--news .view-content,
.block__news--home .view-content, .paragraph__container .paragraphs-item-statistics .paragraph__title--statistics,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__title--statistics, .node-type-domain .field__domain--documents .label-above, .node-type-domain .field__domain--news .label-above, .node-type-domain .field__domain--links .label-above, .block__domains .group__wrapper h3 {
  display: -webkit-flex;
  display: flex;
}

.view-business-cases .view-content, .view-documents--domains .view-content,
.view-documents--news .view-content,
.view-documents--page .view-content, .block__domains .view-content, .view-news--domains .view-content,
.view-news--news .view-content,
.block__news--home .view-content {
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.view-business-cases .view-content, .view-documents--domains .view-content,
.view-documents--news .view-content,
.view-documents--page .view-content, .block__domains .view-content, .view-news--domains .view-content,
.view-news--news .view-content,
.block__news--home .view-content {
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.block__doormat.last ul, .block__menu--footer ul, .block__menu--lang ul, .block__menu--main ul, .block__menu--secondary ul, .block__menu--tertiary ul, .tabs, .block__members ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  white-space: nowrap;
}

.block__contactperson, .paragraph__container .paragraphs-item-banner-full-width .paragraph__image,
.field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image, .view-business-cases .more-link, .view-documents--domains .more-link,
.view-documents--news .more-link,
.view-documents--page .more-link, .block__members .group-left,
.block__members .group-middle,
.block__members .group-right, .view-news--domains .more-link,
.view-news--news .more-link,
.block__news--home .more-link, .footer-wrapper {
  text-align: center;
}

.block__doormat li a:hover, .block__doormat li a:focus, .block__doormat.block__doormat--center a, .block__menu--lang li a:hover, .block__menu--lang li a:focus, .block__menu--lang li a.active, .pager li a:hover, .pager li a:focus, .block__mailchimp .views-row a:hover, .block__mailchimp .views-row a:focus, .block__publications .views-row a:hover, .block__publications .views-row a:focus {
  text-decoration: underline;
}

.block__doormat li a, .block__doormat.block__doormat--center a:hover, .block__doormat.block__doormat--center a:focus, .block__logout .content a, .block__menu--footer li a:hover, .block__menu--footer li a:focus, .block__menu--lang li a, .block__menu--main li a, .block__menu--secondary li a, .block__menu--tertiary li a, .breadcrumb a,
.breadcrumb span, .pager li a, .paragraph__container .paragraphs-item-menu a:hover, .paragraph__container .paragraphs-item-menu a:focus,
.field-name-field-paragraph .paragraphs-item-menu a:hover,
.field-name-field-paragraph .paragraphs-item-menu a:focus, .block__domains .group__wrapper .views-row a, .block__mailchimp .views-row a, .block__mailchimp .subscribe-link a, .block__publications .views-row a {
  text-decoration: none;
}

.breadcrumb a,
.breadcrumb span {
  text-transform: lowercase;
}

table {
  margin-bottom: 30px;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
html a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.language-switcher-locale-url,
.menu,
ul,
ol,
dl,
.clean {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
}

body {
  background: #fff;
  color: #444;
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
}

img {
  height: auto;
}

.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.text--red {
  color: #c30a2b;
}

table {
  display: block;
  overflow-x: auto;
  border: 0;
  height: auto !important;
}

table th,
table td {
  border: 1px solid #c9c9c9;
  padding: 5px;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .table-wrapper {
    white-space: normal;
  }
}

body {
  font-size: 15px;
  font-family: "Lucida Grande", "Verdana", sans-serif;
  line-height: 24px;
}

a {
  color: #c30a2b;
  transition: all 150ms linear;
}

a:hover, a:focus {
  color: #920820;
}

small {
  font-size: 80%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "Lucida Grande", "Verdana", sans-serif;
  font-weight: 700;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

h1 {
  margin-bottom: 30px;
  font-size: 1.75rem;
  line-height: 2.25rem;
}

@media screen and (min-width: 480px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 640px) {
  h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h2 {
  margin-bottom: 20px;
  color: #c30a2b;
  font-size: 1.375rem;
  line-height: 1.75rem;
}

@media screen and (min-width: 480px) {
  h2 {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

@media screen and (min-width: 640px) {
  h2 {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

@media screen and (min-width: 768px) {
  h2 {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

h3 {
  margin-bottom: 15px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@media screen and (min-width: 480px) {
  h3 {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

@media screen and (min-width: 640px) {
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  h3 {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

h4 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h5 {
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 1.25rem;
}

h6 {
  font-size: 90%;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

ul,
ol {
  margin-bottom: 20px;
}

ul li,
ol li {
  margin-top: 5px;
}

ul li:first-child,
ol li:first-child {
  margin-top: 0;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-top: 10px;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}

ol {
  padding-left: 21px;
}

.block__column--full {
  clear: both;
}

@media screen and (min-width: 640px) {
  .block__contactperson {
    text-align: left;
  }
  .block__contactperson .field-name-field-afbeelding {
    float: left;
    margin-right: 15px;
  }
}

.block__domains--intro {
  margin-bottom: 30px;
}

.block__doormat {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.block__doormat li a {
  color: #444;
}

.block__doormat.last {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.block__doormat.last .twitter:before,
.block__doormat.last .linkedin:before,
.block__doormat.last .youtube:before,
.block__doormat.last .facebook:before {
  content: ' ';
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.block__doormat.last .twitter:before {
  background: url("../../dist/image/twitter.svg") no-repeat center left;
  background-size: 16px 16px;
}

.block__doormat.last .linkedin:before {
  background: url("../../dist/image/linkedin.svg") no-repeat center left;
  background-size: 16px 16px;
}

.block__doormat.last .youtube:before {
  background: url("../../dist/image/youtube.svg") no-repeat center left;
  background-size: 16px 16px;
}

.block__doormat.last .facebook:before {
  background: url("../../dist/image/facebook.svg") no-repeat center left;
  background-size: 16px 16px;
}

.block__doormat.last #mc_embed_signup label {
  display: block;
  margin-bottom: 20px;
}

.block__doormat.last #mc_embed_signup .email {
  width: 62%;
  float: left;
  padding: 8px 6px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
}

.block__doormat.last #mc_embed_signup .button--newsletter {
  width: 38%;
  float: left;
}

.block__doormat.last #mc_embed_signup .button--newsletter input {
  padding: 8px 6px;
  background: #c30a2b;
  color: #fff;
  font-weight: bold;
  border: 1px solid #c30a2b;
  border-radius: 0 4px 4px 0;
}

.block__doormat.last #mc_embed_signup .button--newsletter input:hover, .block__doormat.last #mc_embed_signup .button--newsletter input:focus {
  background-color: #ab0926;
  border-color: #ab0926;
}

@media screen and (min-width: 768px) {
  .block__doormat {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.block__intro .field-name-field-image {
  display: none;
}

@media screen and (min-width: 640px) {
  .block__intro .field-name-body {
    width: 50%;
  }
  .block__intro .field-name-field-image {
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
  }
  .block__intro .field-name-field-image img {
    width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .block__intro .field-name-body {
    width: 40%;
  }
  .block__intro .field-name-field-image {
    top: -20px;
  }
  .block__intro .field-name-field-image img {
    width: 450px;
  }
}

@media screen and (min-width: 960px) {
  .block__intro .field-name-body {
    width: 45%;
  }
  .block__intro .field-name-field-image {
    top: -40px;
  }
  .block__intro .field-name-field-image img {
    width: 560px;
  }
}

@media screen and (min-width: 1145px) {
  .block__intro .field-name-body {
    width: 50%;
  }
  .block__intro .field-name-field-image {
    right: -40px;
  }
}

.block__logout {
  margin-bottom: 20px;
  text-align: right;
}

.block__logout .content a {
  padding: 8px 6px;
  background: #c30a2b;
  color: #fff;
  font-weight: bold;
  border: 1px solid #c30a2b;
  border-radius: 4px;
}

.block__logout .content a:hover, .block__logout .content a:focus {
  background-color: #ab0926;
  border-color: #ab0926;
}

.block__members-info {
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
}

.block__members-info h2:before {
  background: url("../../dist/image/icon_members.png") no-repeat left center;
  background-size: contain;
  content: ' ';
  height: 24px;
  width: 24px;
  margin-right: 1rem;
}

@media screen and (min-width: 640px) {
  .block__members-info {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.block__statistics h2:before {
  background: url("../../dist/image/icon_statistics.svg") no-repeat left center;
  background-size: contain;
  content: ' ';
  height: 24px;
  width: 34px;
  margin-right: 1rem;
}

.form-required {
  color: #b43b4b;
}

.form-item {
  margin-bottom: 1rem;
}

.form-item label {
  display: block;
}

.form-type-radio,
.form-type-checkbox {
  margin-bottom: 0;
}

.form-type-radio label,
.form-type-checkbox label {
  display: inline;
}

textarea,
.form-text,
.form-select,
.form-date,
.form-number,
.form-email,
.form-tel,
.form-url {
  padding: 8px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea.error,
.form-text.error,
.form-select.error,
.form-date.error,
.form-number.error,
.form-email.error,
.form-tel.error,
.form-url.error {
  border-color: #b43b4b;
}

textarea:focus,
.form-text:focus,
.form-select:focus,
.form-date:focus,
.form-number:focus,
.form-email:focus,
.form-tel:focus,
.form-url:focus {
  outline: none;
  border-color: #999;
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.description {
  font-size: .75rem;
  line-height: 1rem;
  margin-bottom: 20px;
}

.block__menu--footer li a {
  color: #444;
}

.block__menu--footer li:before {
  content: ' | ';
  margin: 0 10px;
}

.block__menu--footer li.first:before {
  content: '';
}

@media screen and (min-width: 768px) {
  .block__menu--footer li:before {
    margin: 0;
  }
  .block__menu--footer li.first:before {
    content: ' | ';
  }
}

@media screen and (min-width: 960px) {
  .block__menu--footer li:before {
    margin: 0 10px;
  }
  .block__menu--footer li.first:before {
    content: ' | ';
  }
}

.block__menu--lang {
  position: absolute;
  right: 75px;
  top: 0;
}

@media screen and (min-width: 768px) {
  .block__menu--lang {
    position: inherit;
    right: auto;
    top: auto;
  }
}

.block__menu--lang li {
  float: left;
  height: 85px;
  line-height: 85px;
  margin-top: 0;
}

.block__menu--lang li span {
  color: #fff;
  font-weight: bold;
}

.block__menu--lang li a {
  font-size: 17px;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .block__menu--lang li a {
    font-size: .875rem;
  }
}

@media screen and (min-width: 960px) {
  .block__menu--lang li a {
    font-size: 15px;
  }
}

.block__menu--lang li a.active {
  cursor: default;
}

.block__menu--lang li.first:after {
  content: ' /';
  color: #fff;
  margin-right: 5px;
}

.block__menu--main.contextual-links-region {
  position: static;
}

.block__menu--main li {
  margin-top: 0;
}

.block__menu--main li a {
  padding: 5px 12px;
  display: block;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
}

.block__menu--main li a:hover, .block__menu--main li a:focus {
  background-color: #ab0926;
  color: #fff;
}

.block__menu--main li a.is-active, .block__menu--main li a.active-trail {
  background-color: #fff;
  color: #c30a2b;
}

@media screen and (min-width: 768px) {
  .block__menu--main li {
    float: left;
  }
  .block__menu--main li a {
    padding: 0 6px;
    font-size: .75rem;
    height: 85px;
    line-height: 85px;
  }
  .block__menu--main li.last {
    padding-top: 23.5px;
    margin: 0 5px;
    height: 85px;
    line-height: 85px;
  }
  .block__menu--main li.last a {
    height: auto;
    padding: 6px;
    line-height: 1.5rem;
    border: 1px solid #fff;
    border-radius: 4px;
  }
}

@media screen and (min-width: 960px) {
  .block__menu--main li a {
    font-size: 15px;
    padding: 0 12px;
  }
}

@media screen and (min-width: 1080px) {
  .block__menu--main li.last {
    margin: 0 15px;
  }
  .block__menu--main li.last a {
    padding: 6px 12px;
  }
}

.slicknav_menu {
  display: none;
}

@media screen and (max-width: 767px) {
  .js .block__menu--main .menu {
    display: none;
  }
  .js .slicknav_menu {
    display: block;
    text-align: left;
  }
  .js .slicknav_menu .slicknav_btn {
    position: absolute;
    right: 55px;
    top: 26px;
    text-decoration: none;
  }
  .js .slicknav_menu .slicknav_menutxt {
    display: none;
  }
  .js .slicknav_menu .slicknav_icon {
    display: block;
    position: relative;
    width: 100%;
    height: 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  .js .slicknav_menu .slicknav_icon .slicknav__active-item {
    margin-left: 55px;
    height: 30px;
    line-height: 30px;
    font-family: "MeltowSan", Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    padding-top: 3px;
    color: #fff;
  }
  .js .slicknav_menu .slicknav_icon span {
    display: block;
    position: absolute;
    height: 6px;
    width: 40px;
    background: #fff;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .js .slicknav_menu .slicknav_icon span:nth-child(1) {
    top: 0px;
  }
  .js .slicknav_menu .slicknav_icon span:nth-child(2) {
    top: 12px;
  }
  .js .slicknav_menu .slicknav_icon span:nth-child(3) {
    top: 24px;
  }
  .js .slicknav_menu .slicknav_icon.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .js .slicknav_menu .slicknav_icon.open span:nth-child(2) {
    opacity: 0;
  }
  .js .slicknav_menu .slicknav_icon.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .js .slicknav_menu .slicknav_nav {
    padding-bottom: 15px;
    text-align: center;
  }
  .js .slicknav_menu .slicknav_nav a {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.block__menu--secondary {
  padding: 25px 0;
}

.block__menu--secondary li a {
  font-weight: bold;
  color: #444;
}

.block__menu--secondary li a:hover, .block__menu--secondary li a:focus, .block__menu--secondary li a.is-active, .block__menu--secondary li a.active-trail {
  color: #c30a2b;
}

.block__menu--secondary li:after {
  content: ' | ';
  margin: 0 15px;
}

.block__menu--secondary li:last-child:after {
  content: '';
}

.block__menu--tertiary li {
  margin: 0;
  padding: 5px 0;
  border-top: 1px solid #ccc;
}

.block__menu--tertiary li a {
  color: #444;
}

.block__menu--tertiary li a:hover, .block__menu--tertiary li a:focus, .block__menu--tertiary li a.is-active, .block__menu--tertiary li a.active-trail {
  font-weight: bold;
  color: #c30a2b;
}

.block__menu--tertiary li:last-child {
  border-bottom: 1px solid #ccc;
}

.breadcrumb {
  padding: 15px 0 5px;
}

.breadcrumb a,
.breadcrumb span {
  font-size: .875rem;
  color: #444;
}

.view-business-cases .views-exposed-widgets,
.view-nieuwsberichten .views-exposed-widgets {
  margin-bottom: 20px;
}

.view-business-cases .views-exposed-widgets select,
.view-nieuwsberichten .views-exposed-widgets select {
  height: 40px;
}

.field-collection-container {
  border-bottom: 0;
}

.field-collection-view {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.page-filedepot {
  font-size: 13px;
  line-height: 20px;
}

.page-filedepot .breadcrumb {
  display: none;
}

.page-filedepot .page-title,
.page-filedepot .block__logout {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 0;
}

.page-filedepot .block__logout {
  margin-bottom: 20px;
}

.page-filedepot #filedepot {
  background: #fff !important;
  border: none !important;
}

.page-filedepot #filedepotmodule {
  margin-bottom: 40px;
}

.page-filedepot #filedepotmodule .yui-skin-sam .yui-layout {
  border: none !important;
  background-color: transparent !important;
  margin-left: -100%;
  padding-left: 100%;
  margin-right: -100%;
  padding-right: 100%;
}

.page-filedepot #filedepotmodule .filedepottoolbar {
  height: 40px;
  line-height: 40px;
  background-color: #e5ecca;
}

.page-filedepot #filedepotmodule .filedepottoolbar div:first-child {
  padding-left: 0 !important;
}

.page-filedepot #filedepotmodule .filedepottoolbar .floatleft {
  padding-top: 0;
  line-height: 30px;
}

.page-filedepot #filedepotmodule .filedepottoolbar #multiaction {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}

.page-filedepot #filedepotmodule .filedepottoolbar_searchbox {
  width: auto;
}

.page-filedepot #filedepotmodule .filedepottoolbar_searchbox table {
  background-color: #e5ecca !important;
}

.page-filedepot #filedepotmodule input#searchquery {
  height: 24px !important;
}

.page-filedepot #filedepotmodule .tagsearchboxcontainer {
  display: none !important;
}

.page-filedepot #filedepotmodule .filedetailslink {
  display: inline-block;
  height: 17px;
  overflow: initial;
  width: 820px !important;
}

.page-filedepot #filedepotmodule .floatright a {
  color: #444;
}

.page-filedepot #filedepotmodule .yui-layout-doc {
  background: #fff;
  margin-left: 2px !important;
  max-width: 100%;
}

.page-filedepot #filedepotmodule #newfolderlink {
  margin-top: 3px;
}

.page-filedepot #filedepotmodule .yui-resize-handle,
.page-filedepot #filedepotmodule .yui-resize-handle-r {
  height: 435px !important;
}

.page-filedepot #filedepotmodule .togglefavorite {
  padding-left: 6px;
}

.page-filedepot div.yui-layout-bd-nohd {
  border: 1px solid #b1b1b1 !important;
  margin-top: 10px !important;
  background: #fff !important;
}

.page-filedepot #filelistingheader .filedepotheading {
  background: #b0d2f1;
  padding: 5px 0;
  margin-top: 5px;
}

.page-filedepot #reportheadercontainer {
  height: auto;
  min-height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}

.page-filedepot #bestand_vertaling_fr_row select,
.page-filedepot #bestand_vertaling_en_row select,
.page-filedepot #bestand_vertaling_nl_row select {
  width: 195px !important;
}

.logo {
  padding-top: 15px;
  height: 85px;
  line-height: 85px;
}

.logo:hover, .logo:focus {
  opacity: .8;
}

.logo img {
  width: 125px;
}

.messages {
  border: 3px solid;
  padding: 15px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: bold;
}

.messages.status {
  background-color: #92c25e;
  border-color: #62922d;
}

.messages.warning {
  background-color: #f6813e;
  border-color: #cf4b00;
}

.messages.error {
  background-color: #e98491;
  border-color: #b43b4b;
}

.pager {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  padding-left: 0;
}

.pager .pager-first,
.pager .pager-last {
  display: none;
}

.pager li {
  text-align: center;
  margin-top: 0;
  color: #444;
  border-radius: 50%;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 12px 18px;
}

.pager li a:hover, .pager li a:focus {
  color: #c30a2b;
}

.pager li.pager-current {
  background-color: #c30a2b;
  color: #fff;
}

.pager li.pager-previous, .pager li.pager-next {
  font-size: 1.25rem;
}

.pager li.pager ul li {
  display: inline-block;
  float: none;
}

.paragraph__container .block,
.field-name-field-paragraph .block {
  margin-bottom: 0;
}

.paragraph__container p,
.field-name-field-paragraph p {
  line-height: 1.375rem;
}

.paragraph__container .paragraphs-item-statistics .paragraph__wrapper,
.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__wrapper,
.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__wrapper,
.paragraph__container .paragraphs-item-quote .paragraph__wrapper,
.paragraph__container .paragraphs-item-blok-2-1 .paragraph__wrapper,
.paragraph__container .paragraphs-item-text-image .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-quote .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-blok-2-1 .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-text-image .paragraph__wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.paragraph__container .paragraphs-item-statistics .paragraph__anchor,
.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__anchor,
.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__anchor,
.paragraph__container .paragraphs-item-quote .paragraph__anchor,
.paragraph__container .paragraphs-item-blok-2-1 .paragraph__anchor,
.paragraph__container .paragraphs-item-text-image .paragraph__anchor,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__anchor,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__anchor,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__anchor,
.field-name-field-paragraph .paragraphs-item-quote .paragraph__anchor,
.field-name-field-paragraph .paragraphs-item-blok-2-1 .paragraph__anchor,
.field-name-field-paragraph .paragraphs-item-text-image .paragraph__anchor {
  position: absolute;
  top: 0;
}

.paragraph__container .paragraphs-item-statistics .paragraph__title,
.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__title,
.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__title,
.paragraph__container .paragraphs-item-quote .paragraph__title,
.paragraph__container .paragraphs-item-blok-2-1 .paragraph__title,
.paragraph__container .paragraphs-item-text-image .paragraph__title,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__title,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__title,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__title,
.field-name-field-paragraph .paragraphs-item-quote .paragraph__title,
.field-name-field-paragraph .paragraphs-item-blok-2-1 .paragraph__title,
.field-name-field-paragraph .paragraphs-item-text-image .paragraph__title {
  font-weight: bold;
  margin-bottom: 30px;
}

.paragraph__container .paragraph__intro,
.paragraph__container .paragraph__column,
.paragraph__container .paragraph__text,
.field-name-field-paragraph .paragraph__intro,
.field-name-field-paragraph .paragraph__column,
.field-name-field-paragraph .paragraph__text {
  font-size: 15px;
  line-height: 1.375rem;
}

.paragraph__container .paragraph__color,
.paragraph__container .paragraph__background,
.paragraph__container .paragraph__background--own,
.field-name-field-paragraph .paragraph__color,
.field-name-field-paragraph .paragraph__background,
.field-name-field-paragraph .paragraph__background--own {
  display: none;
}

.paragraph__container .color__dark .paragraph__menu .label-inline,
.paragraph__container .color__dark .paragraph__author,
.paragraph__container .color__dark .paragraph__title,
.paragraph__container .color__dark .paragraph__intro,
.paragraph__container .color__dark .paragraph__column,
.paragraph__container .color__dark .paragraph__text,
.paragraph__container .color__dark p,
.paragraph__container .color__dark a,
.paragraph__container .color__dark span,
.paragraph__container .color__dark strong,
.paragraph__container .color__dark em,
.field-name-field-paragraph .color__dark .paragraph__menu .label-inline,
.field-name-field-paragraph .color__dark .paragraph__author,
.field-name-field-paragraph .color__dark .paragraph__title,
.field-name-field-paragraph .color__dark .paragraph__intro,
.field-name-field-paragraph .color__dark .paragraph__column,
.field-name-field-paragraph .color__dark .paragraph__text,
.field-name-field-paragraph .color__dark p,
.field-name-field-paragraph .color__dark a,
.field-name-field-paragraph .color__dark span,
.field-name-field-paragraph .color__dark strong,
.field-name-field-paragraph .color__dark em {
  color: #444;
}

.paragraph__container .color__white .paragraph__menu .label-inline,
.paragraph__container .color__white .paragraph__author,
.paragraph__container .color__white .paragraph__title,
.paragraph__container .color__white .paragraph__intro,
.paragraph__container .color__white .paragraph__column,
.paragraph__container .color__white .paragraph__text,
.paragraph__container .color__white p,
.paragraph__container .color__white a,
.paragraph__container .color__white span,
.paragraph__container .color__white strong,
.paragraph__container .color__white em,
.field-name-field-paragraph .color__white .paragraph__menu .label-inline,
.field-name-field-paragraph .color__white .paragraph__author,
.field-name-field-paragraph .color__white .paragraph__title,
.field-name-field-paragraph .color__white .paragraph__intro,
.field-name-field-paragraph .color__white .paragraph__column,
.field-name-field-paragraph .color__white .paragraph__text,
.field-name-field-paragraph .color__white p,
.field-name-field-paragraph .color__white a,
.field-name-field-paragraph .color__white span,
.field-name-field-paragraph .color__white strong,
.field-name-field-paragraph .color__white em {
  color: #fff;
}

.paragraph__container .paragraph__anchor,
.field-name-field-paragraph .paragraph__anchor {
  height: 0;
  text-indent: -9999px;
}

.paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text,
.field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text {
  position: absolute;
  bottom: 10px;
}

.paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__title,
.field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 10px;
}

.paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__subtitle,
.field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__subtitle {
  font-size: 1rem;
  line-height: 1.25rem;
}

.paragraph__container .paragraphs-item-banner-full-width .color__dark .paragraph__subtitle,
.field-name-field-paragraph .paragraphs-item-banner-full-width .color__dark .paragraph__subtitle {
  color: #444;
}

.paragraph__container .paragraphs-item-banner-full-width .color__white .paragraph__subtitle,
.field-name-field-paragraph .paragraphs-item-banner-full-width .color__white .paragraph__subtitle {
  color: #fff;
}

.paragraph__container .paragraphs-item-banner-full-width .paragraph__image img,
.field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image img {
  display: block;
}

@media screen and (min-width: 480px) {
  .paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text,
  .field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text {
    bottom: 30px;
  }
  .paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__title,
  .field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__title {
    margin-bottom: 15px;
  }
  .paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__subtitle,
  .field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__subtitle {
    font-size: 22px;
    line-height: 26px;
  }
}

@media screen and (min-width: 640px) {
  .paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text,
  .field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text {
    bottom: 60px;
  }
  .paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__title,
  .field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text .paragraph__title {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 960px) {
  .paragraph__container .paragraphs-item-banner-full-width .paragraph__image--text,
  .field-name-field-paragraph .paragraphs-item-banner-full-width .paragraph__image--text {
    bottom: 150px;
  }
}

.paragraph__container .paragraphs-item-menu,
.field-name-field-paragraph .paragraphs-item-menu {
  min-height: 68px;
}

.paragraph__container .paragraphs-item-menu .paragraph__wrapper,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
}

.paragraph__container .paragraphs-item-menu .paragraph__wrapper.sticky,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__wrapper.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
}

.paragraph__container .paragraphs-item-menu .label-inline,
.paragraph__container .paragraphs-item-menu a,
.field-name-field-paragraph .paragraphs-item-menu .label-inline,
.field-name-field-paragraph .paragraphs-item-menu a {
  font-size: 15px;
  line-height: 18px;
}

.paragraph__container .paragraphs-item-menu .paragraph__menu ul,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__menu ul {
  padding: 0;
  margin: 0;
}

.paragraph__container .paragraphs-item-menu .paragraph__menu li,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__menu li {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #fff;
}

.paragraph__container .paragraphs-item-menu .paragraph__menu li:last-child,
.field-name-field-paragraph .paragraphs-item-menu .paragraph__menu li:last-child {
  border-right: 0;
}

.paragraph__container .paragraphs-item-statistics .paragraph__title--statistics,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__title--statistics {
  -ms-flex-align: center;
  align-items: center;
}

.paragraph__container .paragraphs-item-statistics .paragraph__title--statistics:before,
.field-name-field-paragraph .paragraphs-item-statistics .paragraph__title--statistics:before {
  background: url("../../dist/image/icon_statistics-red.svg") no-repeat left center;
  background-size: contain;
  content: ' ';
  height: 24px;
  width: 34px;
  margin-right: 1rem;
}

.paragraph__container .paragraphs-item-tekstblok-2col .paragraph__intro,
.field-name-field-paragraph .paragraphs-item-tekstblok-2col .paragraph__intro {
  margin-bottom: 40px;
}

.paragraph__container .paragraph__quote,
.field-name-field-paragraph .paragraph__quote {
  padding: 0 60px;
  font-style: italic;
  color: #fff;
  margin-bottom: 20px;
}

.paragraph__container .paragraph__quote p,
.field-name-field-paragraph .paragraph__quote p {
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin-bottom: 40px;
}

.paragraph__container .paragraph__quote p:last-child,
.field-name-field-paragraph .paragraph__quote p:last-child {
  margin-bottom: 0;
}

.paragraph__container .paragraph__author,
.field-name-field-paragraph .paragraph__author {
  padding: 0 60px;
  font-size: 15px;
  line-height: 22px;
}

.paragraph__container .paragraphs-item-tekstblok-1col .paragraph__image,
.field-name-field-paragraph .paragraphs-item-tekstblok-1col .paragraph__image {
  margin: 40px 0;
}

@media screen and (min-width: 640px) {
  .paragraph__container .paragraph__column--left,
  .paragraph__container .paragraph__column--right,
  .field-name-field-paragraph .paragraph__column--left,
  .field-name-field-paragraph .paragraph__column--right {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
  }
  .paragraph__container .paragraph__column--right,
  .field-name-field-paragraph .paragraph__column--right {
    float: right;
    margin-right: 0;
  }
}

.paragraphs-items .paragraph__wrapper {
  margin-left: -100%;
  padding-left: 100%;
  margin-right: -100%;
  padding-right: 100%;
}

.front .tabs,
.page-node-2547 .tabs,
.page-node-2548 .tabs {
  display: none;
}

.tabs {
  margin-bottom: 20px;
}

.tabs li {
  margin-top: 0;
  margin-bottom: 5px;
}

.tabs__container .tabs {
  margin-top: 20px;
}

.node-type-business-case .field-name-field-domain,
.node-type-business-case .field-name-contenttype {
  vertical-align: middle;
}

.node-type-business-case .field-name-field-domain > .field-items > .field-item,
.node-type-business-case .field-name-contenttype > .field-items > .field-item {
  margin-right: 2px;
}

.node-type-business-case .field-name-field-domain-category,
.node-type-business-case .field-name-contenttype .field-item {
  margin-bottom: 10px;
  padding: 0 4px;
  font-weight: bold;
  color: #fff;
}

.node-type-business-case .field-name-field-domain-category {
  background-color: #c30a2b;
}

.node-type-business-case .field-name-contenttype .field-item {
  background-color: #b0d2f1;
}

.node-type-business-case .field-name-contenttype .field-item p {
  margin-bottom: 0;
}

.node-type-business-case .field-name-field-afbeelding {
  margin-bottom: 20px;
}

.node-type-business-case .node-business-case.view-mode-full {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.node-type-domain .column__full {
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .node-type-domain .field__image--float {
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
  }
}

.node-type-domain .label-above {
  margin-bottom: 40px;
}

.node-type-domain .paragraphs-items-field-paragraph .paragraph__wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.node-type-domain .paragraphs-items-field-paragraph .paragraphs-item-banner-full-width .paragraph__wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.node-type-domain .paragraphs-items-field-paragraph .paragraphs-item-banner-full-width .paragraph__image {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}

.node-type-domain .paragraphs-items-field-paragraph .paragraphs-item-banner-full-width .paragraph__image img {
  width: 100%;
}

.node-type-domain .paragraphs-items-field-paragraph .field-item:last-child {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.node-type-domain .view-documents--domains,
.node-type-domain .view-news--domains {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.node-type-domain .field__domains--link {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: rgba(234, 234, 222, 0.71);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.node-type-domain .field-collection-container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.node-type-domain .field__domain--documents .label-above {
  -ms-flex-align: center;
  align-items: center;
}

.node-type-domain .field__domain--documents .label-above:before {
  background: url("../../dist/image/icon_documents.svg") no-repeat left center;
  background-size: contain;
  content: ' ';
  height: 32px;
  width: 24px;
  margin-right: 1rem;
}

.node-type-domain .field__domain--news .label-above {
  -ms-flex-align: center;
  align-items: center;
}

.node-type-domain .field__domain--news .label-above:before {
  background: url("../../dist/image/icon_news.svg") no-repeat left center;
  background-size: contain;
  content: ' ';
  height: 33px;
  width: 33px;
  margin-right: 1rem;
}

.node-type-domain .field__domain--links .label-above {
  -ms-flex-align: center;
  align-items: center;
}

.node-type-domain .field__domain--links .label-above:before {
  background: url("../../dist/image/icon_links.svg") no-repeat left center;
  background-size: contain;
  content: ' ';
  height: 29px;
  width: 26px;
  margin-right: 1rem;
}

.node-type-domain .field__domain--links .field-name-field-link .field-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.node-type-ct-event .datum,
.node-type-ct-event .locatie {
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
}

.node-type-ct-event .datum .label-inline,
.node-type-ct-event .locatie .label-inline {
  font-weight: bold;
}

.node-type-ct-news .field-name-field-domain,
.node-type-ct-news .field-name-contenttype,
.node-type-ct-news .field-name-field-type {
  vertical-align: middle;
}

.node-type-ct-news .field-name-field-domain > .field-items > .field-item,
.node-type-ct-news .field-name-contenttype > .field-items > .field-item,
.node-type-ct-news .field-name-field-type > .field-items > .field-item {
  margin-right: 2px;
}

.node-type-ct-news .field-name-field-domain-category,
.node-type-ct-news .field-name-contenttype .field-item,
.node-type-ct-news .field-name-field-type .field-item {
  margin-bottom: 10px;
  padding: 0 4px;
  font-weight: bold;
  color: #fff;
}

.node-type-ct-news .field-name-field-domain-category {
  background-color: #c30a2b;
}

.node-type-ct-news .field-name-contenttype .field-item,
.node-type-ct-news .field-name-field-type .field-item {
  background-color: #b0d2f1;
}

.node-type-ct-news .field-name-contenttype .field-item p,
.node-type-ct-news .field-name-field-type .field-item p {
  margin-bottom: 0;
}

.node-type-ct-news .field-name-field-datum {
  color: #c30a2b;
  font-weight: bold;
}

.node-type-ct-news .field-name-field-teaser,
.node-type-ct-news .field-name-field-datum {
  margin-bottom: 10px;
}

.node-type-ct-news .field-name-field-bijlagen .field-label {
  margin-bottom: 5px;
}

.node-type-ct-news .field-name-field-bijlagen .file .file-icon,
.node-type-ct-news .field-name-field-bijlagen .file .file-size {
  display: none;
}

.page-node-2547 .breadcrumb,
.page-node-2547 .page-title,
.page-node-2548 .breadcrumb,
.page-node-2548 .page-title {
  display: none;
}

.block__cases h2 {
  margin-bottom: 40px;
}

.view-business-cases .views-row {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.view-business-cases .views-row.views-row-last {
  border-bottom: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 640px) {
  .view-business-cases .views-row {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
    padding-bottom: 0;
    border-bottom: 0;
  }
  .view-business-cases .views-row:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .view-business-cases .views-row {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
  }
  .view-business-cases .views-row:nth-child(2n) {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
  }
  .view-business-cases .views-row:nth-child(3n) {
    float: right;
    margin-right: 0;
  }
}

.view-business-cases .field-name-field-domain > .field-items > .field-item {
  margin-right: 2px;
}

.view-business-cases .field-name-field-domain-category {
  margin-bottom: 10px;
  padding: 0 4px;
  font-weight: bold;
  color: #fff;
  background-color: #c30a2b;
}

.view-business-cases .more-link {
  display: block;
  margin-top: 30px;
}

.view-documents--domains .views-row,
.view-documents--news .views-row,
.view-documents--page .views-row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.view-documents--domains .views-row.views-row-last,
.view-documents--news .views-row.views-row-last,
.view-documents--page .views-row.views-row-last {
  padding-bottom: 0;
  border-bottom: 0;
}

@media screen and (min-width: 640px) {
  .view-documents--domains .views-row,
  .view-documents--news .views-row,
  .view-documents--page .views-row {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: 0;
  }
  .view-documents--domains .views-row:nth-child(2n),
  .view-documents--news .views-row:nth-child(2n),
  .view-documents--page .views-row:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
}

.view-documents--domains .field__preteaser,
.view-documents--news .field__preteaser,
.view-documents--page .field__preteaser {
  float: left;
  padding: 0 4px;
  margin-right: 5px;
  color: #fff;
  background-color: #999;
  font-size: .875rem;
}

.view-documents--domains .field__preteaser.artikel,
.view-documents--news .field__preteaser.artikel,
.view-documents--page .field__preteaser.artikel {
  background-color: #527B97;
}

.view-documents--domains .field__preteaser.persbericht,
.view-documents--news .field__preteaser.persbericht,
.view-documents--page .field__preteaser.persbericht {
  background-color: #c30a2b;
}

.view-documents--domains .field-name-field-bijlagen .file .file-icon,
.view-documents--domains .field-name-field-bijlagen .file .file-size,
.view-documents--news .field-name-field-bijlagen .file .file-icon,
.view-documents--news .field-name-field-bijlagen .file .file-size,
.view-documents--page .field-name-field-bijlagen .file .file-icon,
.view-documents--page .field-name-field-bijlagen .file .file-size {
  display: none;
}

.block__news--documents {
  margin: 30px 0;
  padding: 30px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.block__news--documents .view-documents--news .view-content {
  margin-bottom: 30px;
}

.block__domains .group__wrapper {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 40px;
}

@media screen and (min-width: 640px) {
  .block__domains .group__wrapper {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
  }
  .block__domains .group__wrapper:nth-child(2n) {
    float: right;
    margin-right: 0;
  }
}

@media screen and (min-width: 960px) {
  .block__domains .group__wrapper {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
  }
  .block__domains .group__wrapper:nth-child(2n) {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
  }
  .block__domains .group__wrapper:nth-child(3n) {
    float: right;
    margin-right: 0;
  }
}

.block__domains .group__wrapper h3 {
  -ms-flex-align: center;
  align-items: center;
}

.block__domains .group__wrapper h3 img {
  width: 36px;
  margin-right: 10px;
}

.block__domains .group__wrapper .views-row {
  margin-left: 50px;
  border-top: 1px solid #999;
}

.block__domains .group__wrapper .views-row.views-row-last {
  border-bottom: 1px solid #999;
}

.block__domains .group__wrapper .views-row a {
  color: #444;
}

.block__domains .group__wrapper .views-row a:hover, .block__domains .group__wrapper .views-row a:focus {
  color: #777777;
}

.block__members {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.block__members .group-left,
.block__members .group-middle,
.block__members .group-right {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  float: none;
}

@media screen and (min-width: 640px) {
  .block__members .group-left,
  .block__members .group-middle,
  .block__members .group-right {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
  }
  .block__members .group-middle,
  .block__members .group-right {
    text-align: left;
  }
  .block__members .group-right {
    float: right;
    margin-right: 0;
  }
}

.block__members li {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.block__members li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.block__members .field-name-field-postcode {
  margin-right: 5px;
}

.block__news--spotlight {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.block__news--spotlight h2 {
  color: #444;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@media screen and (min-width: 640px) {
  .block__news--spotlight .column__half {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
  }
  .block__news--spotlight .column__half.column__half--second {
    float: right;
    margin-right: 0;
  }
}

.block__news--home .field-name-field-bijlagen .file .file-icon,
.block__news--home .field-name-field-bijlagen .file .file-size {
  display: none;
}

.view-news--domains .view-content,
.view-news--news .view-content,
.block__news--home .view-content {
  margin-bottom: 20px;
}

.view-news--domains .field__news--image,
.view-news--news .field__news--image,
.block__news--home .field__news--image {
  margin-bottom: 10px;
}

.view-news--domains .more-link,
.view-news--news .more-link,
.block__news--home .more-link {
  display: block;
  margin-top: 30px;
}

.view-news--domains .views-row,
.view-news--news .views-row,
.block__news--home .views-row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.view-news--domains .views-row.views-row-last,
.view-news--news .views-row.views-row-last,
.block__news--home .views-row.views-row-last {
  padding-bottom: 0;
  border-bottom: 0;
}

@media screen and (min-width: 640px) {
  .view-news--domains .views-row .field__news--image,
  .view-news--news .views-row .field__news--image,
  .block__news--home .views-row .field__news--image {
    float: left;
    width: 49%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 960px) {
  .view-news--domains .views-row,
  .view-news--news .views-row,
  .block__news--home .views-row {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
    border-bottom: 0;
  }
  .view-news--domains .views-row .field__news--image,
  .view-news--news .views-row .field__news--image,
  .block__news--home .views-row .field__news--image {
    float: none;
    width: 100%;
    margin: 0 0 10px;
  }
  .view-news--domains .views-row:nth-child(3n),
  .view-news--news .views-row:nth-child(3n),
  .block__news--home .views-row:nth-child(3n) {
    float: right;
    margin-right: 0;
  }
}

.view-news--domains .field__preteaser,
.view-news--news .field__preteaser,
.block__news--spotlight .field__preteaser,
.block__news--home .field__preteaser {
  float: left;
  padding: 0 4px;
  margin-right: 5px;
  color: #fff;
  background-color: #999;
  font-size: .875rem;
}

.view-news--domains .field__preteaser.artikel,
.view-news--news .field__preteaser.artikel,
.block__news--spotlight .field__preteaser.artikel,
.block__news--home .field__preteaser.artikel {
  background-color: #527B97;
}

.view-news--domains .field__preteaser.persbericht,
.view-news--news .field__preteaser.persbericht,
.block__news--spotlight .field__preteaser.persbericht,
.block__news--home .field__preteaser.persbericht {
  background-color: #c30a2b;
}

.view-news--news {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.block__mailchimp {
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .block__mailchimp {
    width: 48.57143%;
    float: right;
    margin-right: 0;
  }
}

.block__mailchimp .view-content {
  margin-bottom: 20px;
}

.block__mailchimp .views-row {
  margin-bottom: 1rem;
}

.block__mailchimp .views-row a {
  color: #444;
}

.block__mailchimp .more-link {
  margin-right: 1rem;
}

.block__mailchimp .subscribe-link a {
  display: block;
  padding: 6px;
  background: #c30a2b;
  color: #fff;
  font-weight: bold;
  border: 1px solid #c30a2b;
  border-radius: 4px;
}

.block__mailchimp .subscribe-link a:hover, .block__mailchimp .subscribe-link a:focus {
  background-color: #ab0926;
  border-color: #ab0926;
}

.block__publications {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

@media screen and (min-width: 640px) {
  .block__publications {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.block__publications .view-content {
  margin-bottom: 20px;
}

.block__publications .views-row {
  margin-bottom: 1rem;
}

.block__publications .views-row a {
  color: #444;
}

.page-above-content {
  border-bottom: 1px solid #999;
}

.front .page-above-content {
  padding: 50px 0;
  background-color: #e5ecca;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

@media screen and (min-width: 640px) {
  .front .page-above-content {
    min-height: 360px;
  }
}

.page-node-2547 .page-above-content,
.page-node-2548 .page-above-content {
  padding: 50px 0;
  background-color: rgba(160, 198, 223, 0.3);
  border-bottom: 0;
}

.page-below-content {
  padding: 30px 0;
  background-color: #c30a2b;
  color: #fff;
}

.page-below-content h2 {
  color: #fff;
}

.page-below-content .button--primary {
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  .page-below-content .block__column--half {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%;
  }
  .page-below-content .block__statistics {
    float: right;
    margin-right: 0;
  }
}

.section--content {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .content-wrapper .sidebar {
    width: 22.85714%;
    float: left;
    margin-right: 2.85714%;
  }
  .content-wrapper .content--content {
    width: 74.28571%;
    float: right;
    margin-right: 0;
  }
}

.content--content {
  padding: 30px 0;
}

.page-doormat {
  border-top: 1px solid #ccc;
  padding: 30px 0;
}

@media screen and (min-width: 768px) {
  .page-doormat .block__doormat {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%;
  }
  .page-doormat .block__doormat.last {
    float: right;
    margin-right: 0;
  }
}

.footer-wrapper {
  border-top: 1px solid #ccc;
  padding: 30px 0;
  color: #444;
  font-size: .875rem;
  font-weight: bold;
}

.footer-wrapper .footer__box {
  margin-bottom: 1rem;
}

.footer-wrapper .footer__box.last {
  margin-bottom: 0;
  font-weight: normal;
}

@media screen and (min-width: 768px) {
  .footer-wrapper .footer__box {
    display: inline-block;
    margin-bottom: 0;
  }
  .footer-wrapper .footer__box.last {
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 960px) {
  .footer-wrapper {
    text-align: left;
  }
  .footer-wrapper .footer__box.last {
    margin-top: 4px;
    float: right;
  }
}

.page-header {
  background: #c30a2b;
}

@media screen and (min-width: 768px) {
  .page-header .page-header--left,
  .page-header .page-header--right {
    display: inline-block;
  }
  .page-header .page-header--right {
    float: right;
    height: 85px;
  }
  .page-header .page-header--right .block__menu--main,
  .page-header .page-header--right .block__menu--lang {
    display: inline-block;
  }
}

.sidebar {
  padding: 30px 0;
}
