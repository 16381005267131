.node-type-ct-news {
  .field-name-field-domain,
  .field-name-contenttype,
  .field-name-field-type {
    @extend %inline-block;
    vertical-align: middle;

    > .field-items > .field-item {
      @extend %inline-block;
      margin-right: 2px;
    }
  }

  .field-name-field-domain-category,
  .field-name-contenttype .field-item,
  .field-name-field-type .field-item {
    margin-bottom: 10px;
    padding: 0 4px;
    font-weight: bold;
    color: $white;
  }

  .field-name-field-domain-category {
    background-color: $secondary-color;
  }

  .field-name-contenttype .field-item,
  .field-name-field-type .field-item {
    background-color: $tertiary-color;

    p {
      margin-bottom: 0;
    }
  }

  .field-name-field-datum {
    color: $secondary-color;
    font-weight: bold;
  }

  .field-name-field-teaser,
  .field-name-field-datum {
    margin-bottom: 10px;
  }

  .field-name-field-bijlagen {
    .field-label {
      margin-bottom: 5px;
    }

    .file {
      a {
        @extend %button-more;
      }

      .file-icon,
      .file-size {
        display: none;
      }
    }
  }
}
