.block__intro {
  @extend %pos-relative;
  
  .field-name-field-image {
    display: none;
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    .field-name-body {
      width: 50%;
    }

    .field-name-field-image {
      display: block;
      position: absolute;
      top: 0px;
      right: 0;

      img {
        width: 300px;
      }
    }
  }

  @media screen and (min-width: $tablet-portrait) {
    .field-name-body {
      width: 40%;
    }

    .field-name-field-image {
      top: -20px;

      img {
        width: 450px;
      }
    }
  }

  @media screen and (min-width: $desktop-narrow) {
    .field-name-body {
      width: 45%;
    }

    .field-name-field-image {
      top: -40px;

      img {
        width: 560px;
      }
    }
  }

  @media screen and (min-width: $desktop + 65px) {
    .field-name-body {
      width: 50%;
    }

    .field-name-field-image {
      right: -40px;
    }
  }
}
