//
// @file
//
// This file includes everything related to the basic typography
//

// ########## General ########## //
body {
  font-size: 15px;
  font-family: $default-font-family;
  line-height: $base-line-height;
}

a {
  color: $link-color;
  transition: all 150ms linear;

  &:hover,
  &:focus {
    color: $link-color-hover;
  }
}

small {
  font-size: 80%;
}

// ########## Headings ########## //
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: $heading-font-family;
  font-weight: 700;

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
}

h1 {
  margin-bottom: 30px;
  font-size: 1.75rem;
  line-height: 2.25rem;

  @media screen and (min-width: $mobile-landscape-narrow) {
    font-size: $font-size-heading-1;
    line-height: 2.5rem;
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  @media screen and (min-width: $tablet-portrait) {
    font-size: $font-size-heading-1;
    line-height: 2.5rem;
  }
}

h2 {
  margin-bottom: 20px;
  color: $secondary-color;
  font-size: 1.375rem;
  line-height: 1.75rem;

  @media screen and (min-width: $mobile-landscape-narrow) {
    font-size: $font-size-heading-2;
    line-height: 2rem;
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: $tablet-portrait) {
    font-size: $font-size-heading-2;
    line-height: 2rem;
  }
}

h3 {
  margin-bottom: 15px;
  font-size: 1.25rem;
  line-height: 1.5rem;

  @media screen and (min-width: $mobile-landscape-narrow) {
    font-size: $font-size-heading-3;
    line-height: 1.75rem;
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: $tablet-portrait) {
    font-size: $font-size-heading-3;
    line-height: 1.75rem;
  }
}

h4 {
  margin-bottom: 10px;
  font-size: $font-size-heading-4;
  line-height: 1.5rem;
}

h5 {
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 1.25rem;
}

h6 {
  font-size: 90%;
}


p {
  margin-top: 0;
  margin-bottom: 1em;
}

ul,
ol {
  margin-bottom: 20px;

  li {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }

  ul,
  ol {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

ul {
  padding-left: 20px;
}

ol {
  padding-left: 21px;
}
