.block__menu--tertiary {
  ul {
    @extend %list-clean;
  }

  li {
    margin: 0;
    padding: 5px 0;
    border-top: 1px solid #ccc;

    a {
      @extend %typ-no-underline;
      color: $primary-color;

      &:hover,
      &:focus,
      &.is-active,
      &.active-trail {
        font-weight: bold;
        color: $secondary-color;
      }
    }

    &:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
}
