//
// @file
// This file includes all extendables related to typography
//

%typ-hide {
  border: 0;
  background-color: transparent;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

// Text overflow
// Requires inline-block or block for proper styling
%typ-overflow {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%typ-center {
  text-align: center;
}

%typ-underline {
  text-decoration: underline;
}

%typ-no-underline {
  text-decoration: none;
}

%typ-uppercase {
  text-transform: uppercase;
}

%typ-lowercase {
  text-transform: lowercase;
}

%typ-italic {
  font-style: italic;
}
