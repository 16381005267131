.block__statistics {
  h2:before {
    @extend %inline-block;
    background: url('../../dist/image/icon_statistics.svg') no-repeat left center;
    background-size: contain;
    content: ' ';
    height: 24px;
    width: 34px;
    margin-right: 1rem;
  }
}
