//
// @file
// This file includes all variables related to colors
//

$three-sign: #d6003d; // Red used in 3sign logo

$black: #000;
$white: #fff;

$error-dark: #b43b4b;
$error-light: #e98491;
$status-light: #92c25e;
$status-dark: #62922d;
$warning-light: #f6813e;
$warning-dark: #cf4b00;

$primary-color: #444;

$secondary-color: #c30a2b;

$tertiary-color: #b0d2f1;

$link-color: $secondary-color;
$link-color-hover: darken($link-color, 10%);

$body-color: $primary-color;
$body-background: $white;

$grey-dark: #999;
$grey: #c9c9c9;
$grey-light: #f1f1f1;
$grey-blue: #527B97;

$green-light: #e5ecca;
