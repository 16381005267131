.block__members {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;

  .group-left,
  .group-middle,
  .group-right {
    @extend %typ-center;
    @include span(12 of 12);
    float: none;
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    .group-left,
    .group-middle,
    .group-right {
      @include span(4 of 12);
    }

    .group-middle,
    .group-right {
      text-align: left;
    }

    .group-right {
      @include last;
    }
  }

  ul {
    @extend %list-clean;
  }

  li {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey-light;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .field-name-field-postcode {
    margin-right: 5px;
  }

  .label-inline,
  .field-name-field-postcode,
  .field-name-field-gemeente,
  .field-name-field-gemeente-fr {
    @extend %inline-block;
  }

  .group-left {
    @extend %typ-center;
  }
}
