%flex {
  display: -webkit-flex;
  display: flex;
}

%flex-wrapper {
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

%flex-stretch {
  @extend %flex;
  @extend %flex-wrapper;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
}

%flex-center {
  @extend %flex;
  @extend %flex-wrapper;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
