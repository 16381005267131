.page-below-content {
  @extend %clearfix;
  padding: 30px 0;
  background-color: $secondary-color;
  color: $white;
  
  h2 {
    color: $white;
  }

  .button--primary {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    .block__column--half {
      @include span(6 of 12);
    }

    .block__statistics {
      @include last;
    }
  }
}
