//
// @file
// This file includes everything related to the basic setup
// Below the typography and wysiwyg styles are imported
//

// Set all dom nodes to border-box
@if $default-border-box {
  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }
}

// Use font-smoothing
@if $default-font-smoothing {
  html,
  html a {
    @include fontsmoothing();
  }
}

// Disable outlining
@if $default-disable-outline {
  *:active,
  *:hover {
    outline: 0;
  }
}

// clean menu items
@if $default-clean-menus {
  .language-switcher-locale-url,
  .menu,
  ul,
  ol,
  dl,
  .clean {
    margin: 0;
    padding: 0;
  }
}

html {
  font-size: 100%;
}

body {
  background: $body-background;
  color: $body-color;
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
}

img {
  height: auto;
}

.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.text--red {
  color: $secondary-color;
}
