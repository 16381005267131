%button {
  border: 1px solid;
  border-radius: $default-border-radius;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
}

%button-primary {
  @extend %button;
  border-color: $grey-blue;
  color: $grey-blue;
  background-color: $white;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $white;
    background-color: $grey-blue;
  }
}

%button-secondary {
  @extend %button;
  border-color: $primary-color;
  color: $white;
  background-color: $primary-color;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $white;
    background-color: lighten($primary-color, 5);
  }
}

%button-more {
  @extend %button;
  border-color: $grey-blue;
  border-radius: 0;
  background-color: $grey-blue;
  color: $white;
  padding: 0 4px;
  font-weight: bold;
  font-size: .875rem;

  &:hover,
  &:focus {
    color: $grey-blue;
    background-color: $white;
  }
}
