.front,
.page-node-2547,
.page-node-2548 {
  .tabs {
    display: none;
  }
}

.tabs {
  @extend %list-clean;
  margin-bottom: 20px;

  li {
    @extend %inline-block;
    margin-top: 0;
    margin-bottom: 5px;
  }

  a {
    @extend %button-secondary;
  }
}

.tabs__container .tabs {
  margin-top: 20px;
}
