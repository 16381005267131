.section--content {
  overflow: hidden;
}

.content-wrapper {
  @media screen and (min-width: $tablet-portrait) {
    .sidebar {
      @include span(3 of 12);
    }

    .content--content {
      @include span(last 9 of 12);
    }
  }
}

.content--content {
  padding: 30px 0;
}
