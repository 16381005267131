.pager {
  @include span (12 of 12);
  text-align: center;
  padding-left: 0;

  .pager-first,
  .pager-last {
    display: none;
  }

  li {
    @extend %inline-block;
    text-align: center;
    margin-top: 0;
    color: $primary-color;
    border-radius: 50%;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 12px 18px;

    a {
      @extend %typ-no-underline;

      &:hover,
      &:focus {
        @extend %typ-underline;
        color: $secondary-color;
      }
    }

    &.pager-current {
      background-color: $secondary-color;
      color: $white;
    }

    &.pager-previous,
    &.pager-next {
      font-size: 1.25rem;
    }
  }

  li.pager ul li {
    display: inline-block;
    float: none;
  }
}
