.view-documents--domains,
.view-documents--news,
.view-documents--page {
  .view-content {
    @extend %flex-stretch;
  }

  .views-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey-light;

    &.views-row-last {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    .views-row {
      @include span(6 of 12);
      margin-bottom: 20px;
      padding-bottom: 0;
      border-bottom: 0;

      &:nth-child(2n) {
        @include last;
      }
    }
  }

  .field__preteaser {
    float: left;
    padding: 0 4px;
    margin-right: 5px;
    color: $white;
    background-color: $grey-dark;
    font-size: .875rem;

    &.artikel {
      background-color: $grey-blue;
    }

    &.persbericht {
      background-color: $secondary-color;
    }
  }

  .field-name-field-bijlagen {
    .file {
      a {
        @extend %button-more;
      }

      .file-icon,
      .file-size {
        display: none;
      }
    }
  }

  .more-link {
    @extend %typ-center;

    a {
      @extend %button-primary;
    }
  }
}

.block__news--documents {
  margin: 30px 0;
  padding: 30px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  .view-documents--news {
    .view-content {
      margin-bottom: 30px;
    }
  }
}
