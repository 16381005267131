.block__menu--secondary {
  padding: 25px 0;

  ul {
    @extend %list-clean;
  }

  li {
    @extend %inline-block;

    a {
      @extend %typ-no-underline;
      font-weight: bold;
      color: $primary-color;

      &:hover,
      &:focus,
      &.is-active,
      &.active-trail {
        color: $secondary-color;
      }
    }

    &:after {
      content: ' | ';
      margin: 0 15px;
    }

    &:last-child:after {
      content: '';
    }
  }
}
