//
// @file
// This file includes all mixins related to the typography
//

@mixin fontsmoothing ($smooting: antialiased, $uses-text-shadow: false) {
  -webkit-font-smoothing: $smooting;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: $smooting;
  -ms-font-smoothing: $smooting;
  font-smoothing: $smooting;

  @if $uses-text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  }
}
