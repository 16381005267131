.node-type-ct-event {
  .datum,
  .locatie {
    display: block;
    text-decoration: none;
    margin-bottom: 10px;

    .label-inline {
      @extend %inline-block;
      font-weight: bold;
    }
  }
}
