.block__cases {
  h2 {
    margin-bottom: 40px;
  }
}

.view-business-cases {
  .view-content {
    @extend %flex-stretch;
  }

  .views-row {
    @include span(12 of 12);
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey-light;

    &.views-row-last {
      border-bottom: 0;
      padding-bottom: 0;
    }

    @media screen and (min-width: $mobile-landscape-wide) {
      @include span(6 of 12);
      padding-bottom: 0;
      border-bottom: 0;

      &:nth-child(2n) {
        @include last;
      }
    }

    @media screen and (min-width: $desktop-narrow) {
      @include span(4 of 12);

      &:nth-child(2n) {
        @include span(4 of 12);
      }

      &:nth-child(3n) {
        @include last;
      }
    }
  }

  .field-name-field-domain > .field-items > .field-item {
    @extend %inline-block;
    margin-right: 2px;
  }

  .field-name-field-domain-category {
    @extend %inline-block;
    margin-bottom: 10px;
    padding: 0 4px;
    font-weight: bold;
    color: $white;
    background-color: $secondary-color;
  }

  .more-link {
    @extend %typ-center;
    display: block;
    margin-top: 30px;

    a {
      @extend %button-primary;
    }
  }
}
