.block__contactperson {
  @extend %typ-center;

  @media screen and (min-width: $mobile-landscape-wide) {
    text-align: left;

    .field-name-field-afbeelding {
      float: left;
      margin-right: 15px;
    }
  }

  .label-inline {
    @extend %inline-block;
  }
}
