.paragraph__container,
.field-name-field-paragraph {
  .container {
    @extend %pos-relative;
  }

  .block {
    margin-bottom: 0;
  }

  p {
    line-height: 1.375rem;
  }

  .paragraphs-item-statistics,
  .paragraphs-item-tekstblok-2col,
  .paragraphs-item-tekstblok-1col,
  .paragraphs-item-quote,
  .paragraphs-item-blok-2-1,
  .paragraphs-item-text-image {
    @extend %pos-relative;

    .paragraph__wrapper {
      @extend %clearfix;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .paragraph__anchor {
      position: absolute;
      top: 0;
    }

    .paragraph__title {
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .paragraph__intro,
  .paragraph__column,
  .paragraph__text {
    font-size: 15px;
    line-height: 1.375rem;
  }

  .paragraph__color,
  .paragraph__background,
  .paragraph__background--own {
    display: none;
  }

  .color__dark {
    .paragraph__menu .label-inline,
    .paragraph__author,
    .paragraph__title,
    .paragraph__intro,
    .paragraph__column,
    .paragraph__text,
    p,
    a,
    span,
    strong,
    em {
     color: $primary-color;
    }
  }

  .color__white {
    .paragraph__menu .label-inline,
    .paragraph__author,
    .paragraph__title,
    .paragraph__intro,
    .paragraph__column,
    .paragraph__text,
    p,
    a,
    span,
    strong,
    em {
      color: $white;
    }
  }

  .paragraph__anchor {
    height: 0;
    text-indent: -9999px;
  }

  .paragraphs-item-banner-full-width {
    @extend %pos-relative;

    .paragraph__image--text {
      position: absolute;
      bottom: 10px;

      .paragraph__title {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-bottom: 10px;
      }

      .paragraph__subtitle {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }

    .color__dark {
      .paragraph__subtitle {
        color: $primary-color;
      }
    }

    .color__white {
      .paragraph__subtitle {
        color: $white;
      }
    }

    .paragraph__image {
      @extend %typ-center;

      img {
        display: block;
      }
    }

    @media screen and (min-width: $mobile-landscape-narrow) {
      .paragraph__image--text {
        bottom: 30px;

        .paragraph__title {
          margin-bottom: 15px;
        }

        .paragraph__subtitle {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }

    @media screen and (min-width: $mobile-landscape-wide) {
      .paragraph__image--text {
        bottom: 60px;

        .paragraph__title {
          margin-bottom: 20px;
        }
      }
    }

    @media screen and (min-width: $desktop-narrow) {
      .paragraph__image--text {
        bottom: 150px;
      }
    }
  }

  .paragraphs-item-menu {
    min-height: 68px;

    .paragraph__wrapper {
      padding-top: 24px;
      padding-bottom: 24px;

      &.sticky {
        position: fixed;
        top: 0;
        z-index: 100;
      }
    }

    .label-inline {
      @extend %inline-block;
    }

    .label-inline,
    a {
      font-size: 15px;
      line-height: 18px;
    }

    a {
      &:hover,
      &:focus {
        @extend %typ-no-underline;
      }
    }

    .paragraph__menu {
      ul {
        @extend %inline-block;
        padding: 0;
        margin: 0;
      }

      li {
        @extend %inline-block;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid $white;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .paragraphs-item-statistics {
    .paragraph__title--statistics {
      @extend %flex;
      -ms-flex-align: center;
      align-items: center;

      &:before {
        @extend %inline-block;
        background: url('../../dist/image/icon_statistics-red.svg') no-repeat left center;
        background-size: contain;
        content: ' ';
        height: 24px;
        width: 34px;
        margin-right: 1rem;
      }
    }
  }

  .paragraphs-item-tekstblok-2col {
    .paragraph__intro {
      margin-bottom: 40px;
    }
  }

  .paragraph__quote {
    padding: 0 60px;
    font-style: italic;
    color: $white;
    margin-bottom: 20px;

    p {
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .paragraph__author {
    padding: 0 60px;
    font-size: 15px;
    line-height: 22px;
  }

  .paragraphs-item-tekstblok-1col {
    .paragraph__image {
      margin: 40px 0;
    }
  }

  @media screen and (min-width: $mobile-landscape-wide) {
    .paragraph__column--left,
    .paragraph__column--right {
      @include span(6 of 12);
    }

    .paragraph__column--right {
      @include last;
    }
  }
}

.paragraphs-items {
  .paragraph__wrapper {
    margin-left: -100%;
    padding-left: 100%;
    margin-right: -100%;
    padding-right: 100%;
  }
}
