//
// @file
// This file includes all variables related to the box model
// Border, radius, padding, margin
//

// Border Radius
$small-border-radius: 4px;
$default-border-radius: 5px;
$large-border-radius: 8px;
