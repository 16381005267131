.block__menu--lang {
  position: absolute;
  right: 75px;
  top: 0;

  @media screen and (min-width: $tablet-portrait) {
    position: inherit;
    right: auto;
    top: auto;
  }

  ul {
    @extend %list-clean;
  }

  li {
    float: left;
    height: 85px;
    line-height: 85px;
    margin-top: 0;

    span {
      color: $white;
      font-weight: bold;
    }
    
    a {
      @extend %typ-no-underline;
      font-size: 17px;
      font-weight: bold;
      color: $white;

      @media screen and (min-width: $tablet-portrait) {
        font-size: .875rem;
      }

      @media screen and (min-width: $desktop-narrow) {
        font-size: 15px;
      }

      &:hover,
      &:focus,
      &.active {
        @extend %typ-underline;
      }

      &.active {
        cursor: default;
      }
    }

    &.first:after {
      content: ' /';
      color: $white;
      margin-right: 5px;
    }
  }
}
