.page-above-content {
  border-bottom: 1px solid $grey-dark;
}

.front .page-above-content {
  padding: 50px 0;
  background-color: $green-light;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;

  @media screen and (min-width: $mobile-landscape-wide) {
    min-height: 360px;
  }
}

.page-node-2547,
.page-node-2548 {
  .page-above-content {
    padding: 50px 0;
    background-color: rgba(160,198,223,0.3);
    border-bottom: 0;
  }
}
