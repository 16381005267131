//
// @file
// This file includes all variables related to typography
//

// Font sizes
$rembase: 16;
$base-font-size: 16px; // Leave at 16px for correct container width
$base-font-size-rem: 1rem;
$base-line-height: 24px; // absolute line-height. For relative line-height (1.385), see http://cssleading.com/

// Font families
$lucida: 'Lucida Grande', 'Verdana', sans-serif;
$aleo: 'Aleo', 'Verdana', sans-serif;
$aleo-bold: 'AleoBold', 'Verdana', sans-serif;

$heading-font-family: $lucida;

$font-size-heading-1: 2rem;
$font-size-heading-2: 1.75rem;
$font-size-heading-3: 1.375rem;
$font-size-heading-4: 1.25rem;
$font-size-heading-5: $base-font-size-rem;

// Vertical Rythm
$rhythm-unit: 'rem';
$rem-with-px-fallback: false;
$round-to-nearest-half-line: true;
