.page-filedepot {
  font-size: 13px;
  line-height: 20px;

  .breadcrumb {
    display: none;
  }
  
  .page-title,
  .block__logout {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 0;
  }

  .block__logout {
    margin-bottom: 20px;
  }

  #filedepot {
    background: $white !important;
    border: none !important;
  }

  #filedepotmodule {
    @extend %clearfix;
    margin-bottom: 40px;

    .yui-skin-sam .yui-layout {
      border: none !important;
      background-color: transparent !important;
      margin-left: -100%;
      padding-left: 100%;
      margin-right: -100%;
      padding-right: 100%;
    }

    .filedepottoolbar {
      height: 40px;
      line-height: 40px;
      background-color: $green-light;

      div:first-child {
        padding-left: 0 !important;
      }

      .floatleft {
        padding-top: 0;
        line-height: 30px;
      }

      #multiaction {
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
      }
    }

    .filedepottoolbar_searchbox {
      width: auto;

      table {
        background-color: $green-light !important;
      }
    }

    input#searchquery {
      height: 24px !important;
    }

    .tagsearchboxcontainer {
      display: none !important;
    }

    .filedetailslink {
      display: inline-block;
      height: 17px;
      overflow: initial;
      width: 820px !important;
    }

    .floatright a {
      color: $primary-color;
    }

    .yui-layout-doc {
      background: $white;
      margin-left: 2px !important;
      max-width: 100%;
    }

    #newfolderlink {
      margin-top: 3px;
    }

    .yui-resize-handle,
    .yui-resize-handle-r {
      height: 435px !important;
    }

    .togglefavorite{
      padding-left: 6px;
    }
  }

  div.yui-layout-bd-nohd {
    border: 1px solid #b1b1b1 !important;
    margin-top: 10px !important;
    background: $white !important;
  }

  #filelistingheader {
    .filedepotheading{
      background: $tertiary-color;
      padding: 5px 0;
      margin-top: 5px;
    }
  }

  #reportheadercontainer{
    height:auto;
    min-height: 28px;
    padding-top : 0;
    padding-bottom:0;
  }

  #bestand_vertaling_fr_row select,
  #bestand_vertaling_en_row select,
  #bestand_vertaling_nl_row select {
    width: 195px !important;
  }
}
