.page-header {
  @extend %pos-relative;
  background: $secondary-color;

  @media screen and (min-width: $tablet-portrait) {
    .page-header--left,
    .page-header--right {
      display: inline-block;
    }

    .page-header--right {
      float: right;
      height: 85px;

      .block__menu--main,
      .block__menu--lang {
        display: inline-block;
      }
    }
  }
}
