.block__doormat {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;

  li {
    a {
      @extend %typ-no-underline;
      color: $primary-color;

      &:hover,
      &:focus {
        @extend %typ-underline;
      }
    }
  }

  &.block__doormat--center {
    a {
      @extend %typ-underline;

      &:hover,
      &:focus {
        @extend %typ-no-underline;
      }
    }
  }

  &.last {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;

    ul {
      @extend %list-clean;
    }

    .twitter,
    .linkedin,
    .youtube,
    .facebook {
      &:before {
        @extend %inline-block;
        content: ' ';
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }

    .twitter {
      &:before {
        background: url('../../dist/image/twitter.svg') no-repeat center left;
        background-size: 16px 16px;
      }
    }

    .linkedin {
      &:before {
        background: url('../../dist/image/linkedin.svg') no-repeat center left;
        background-size: 16px 16px;
      }
    }

    .youtube {
      &:before {
        background: url('../../dist/image/youtube.svg') no-repeat center left;
        background-size: 16px 16px;
      }
    }

    .facebook {
      &:before {
        background: url('../../dist/image/facebook.svg') no-repeat center left;
        background-size: 16px 16px;
      }
    }

    #mc_embed_signup {
      label {
        display: block;
        margin-bottom: 20px;
      }

      .email {
        width: 62%;
        float: left;
        padding: 8px 6px;
        border: 1px solid #ccc;
        border-radius: 4px 0 0 4px;
      }

      .button--newsletter {
        width: 38%;
        float: left;

        input {
          padding: 8px 6px;
          background: $secondary-color;
          color: $white;
          font-weight: bold;
          border: 1px solid $secondary-color;
          border-radius: 0 4px 4px 0;

          &:hover,
          &:focus {
            background-color: darken($secondary-color, 5);
            border-color: darken($secondary-color, 5);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet-portrait) {
  .block__doormat {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}
