.node-type-domain {
  .column__full {
    margin-bottom: 30px;
  }

  .field__image--float {
    @media screen and (min-width: $mobile-landscape-wide) {
      float: right;
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }

  .label-above {
    margin-bottom: 40px;
  }

  .paragraphs-items-field-paragraph {
    .paragraph__wrapper {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .paragraphs-item-banner-full-width {
      .paragraph__wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }

      .paragraph__image {
        width: 100vw;
        position: relative;
        left: 50%;
        margin-left: -50vw;

        img {
          width: 100%;
        }
      }
    }

    .field-item:last-child {
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;
    }
  }

  .view-documents--domains,
  .view-news--domains {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  .field__domains--link {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: rgba(234, 234, 222, .71);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .3);

    .field-name-field-link {
      a {
        @extend %button-more;
      }
    }
  }

  .field-collection-container {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  .field__domain--documents {
    .label-above {
      @extend %flex;
      -ms-flex-align: center;
      align-items: center;

      &:before {
        @extend %inline-block;
        background: url('../../dist/image/icon_documents.svg') no-repeat left center;
        background-size: contain;
        content: ' ';
        height: 32px;
        width: 24px;
        margin-right: 1rem;
      }
    }
  }

  .field__domain--news {
    .label-above {
      @extend %flex;
      -ms-flex-align: center;
      align-items: center;

      &:before {
        @extend %inline-block;
        background: url('../../dist/image/icon_news.svg') no-repeat left center;
        background-size: contain;
        content: ' ';
        height: 33px;
        width: 33px;
        margin-right: 1rem;
      }
    }
  }

  .field__domain--links {
    .label-above {
      @extend %flex;
      -ms-flex-align: center;
      align-items: center;

      &:before {
        @extend %inline-block;
        background: url('../../dist/image/icon_links.svg') no-repeat left center;
        background-size: contain;
        content: ' ';
        height: 29px;
        width: 26px;
        margin-right: 1rem;
      }
    }

    .field-name-field-link {
      .field-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
