.block__mailchimp {
  margin-bottom: 30px;

  @media screen and (min-width: $mobile-landscape-wide) {
    @include span(last 6 of 12);
  }

  .view-content {
    margin-bottom: 20px;
  }

  .views-row {
    margin-bottom: 1rem;

    a {
      @extend %typ-no-underline;
      color: $primary-color;

      &:hover,
      &:focus {
        @extend %typ-underline;
      }
    }
  }

  .more-link,
  .subscribe-link {
    @extend %inline-block;
  }

  .more-link {
    margin-right: 1rem;

    a {
      @extend %button-primary;
    }
  }

  .subscribe-link {
    a {
      @extend %typ-no-underline;
      display: block;
      padding: 6px;
      background: $secondary-color;
      color: $white;
      font-weight: bold;
      border: 1px solid $secondary-color;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: darken($secondary-color, 5);
        border-color: darken($secondary-color, 5);
      }
    }
  }
}
