.page-doormat {
  @extend %clearfix;
  border-top: 1px solid #ccc;
  padding: 30px 0;

  @media screen and (min-width: $tablet-portrait) {
    .block__doormat {
      @include span(4 of 12);

      &.last {
        @include last;
      }
    }
  }
}