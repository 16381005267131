.footer-wrapper {
  @extend %typ-center;
  border-top: 1px solid #ccc;
  padding: 30px 0;
  color: $primary-color;
  font-size: .875rem;
  font-weight: bold;

  .footer__box {
    margin-bottom: 1rem;

    &.last {
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  @media screen and (min-width: $tablet-portrait) {
    .footer__box {
      display: inline-block;
      margin-bottom: 0;

      &.last {
        display: block;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  @media screen and (min-width: $desktop-narrow) {
    text-align: left;

    .footer__box {
      &.last {
        margin-top: 4px;
        float: right;
      }
    }
  }
}
